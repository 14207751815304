import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Contact from "../components/contact"

const SecondPage = () => {
  const { allPagesJson } = useStaticQuery(
    graphql`
      {
        allPagesJson(filter: { page: { eq: "contact" } }) {
          edges {
            node {
              title
              heading
              description
            }
          }
        }
      }
    `
  )

  const data = allPagesJson.edges[0].node

  return (
    <Layout>
      <Seo title={data.title} description={data.description} />
      <Contact data={data} />
    </Layout>
  )
}

export default SecondPage
